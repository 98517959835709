@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "PopinsRegular";
  src: url("./Components/utils//Fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./Components/utils/Fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "PoppinsSemibold";
  src: url("./Components/utils/Fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./Components/utils/Fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "MargaF";
  src: url("./Components/utils/Fonts/Marga\ DEMO.ttf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@layer components {
  .F-JC-AI-CENTER {
    @apply flex items-center justify-center;
  }
  .border-custom {
    @apply border-[1px] border-black;
  }
  .headingT {
    @apply text-[36px] font-PoppinSemibold text-blackText;
  }
  .MediumT {
    @apply text-[18px] font-PoppinMedium text-blackText;
  }
  .inputF {
    @apply text-[18px] font-PoppinsRegular text-blackText w-full min-h-[42px] rounded-[5px] bg-white shadow-sm;
  }
  .absoluteRound {
    @apply absolute text-[18px] text-blackText font-PoppinMedium text-center;
  }

  .NavText {
    @apply text-[15px] text-black;
  }

  .HeadingText {
    @apply text-Heading font-semibold text-duskwood max_md:text-[25px];
  }

  .SubHeadingText {
    @apply text-SubHeading font-medium text-bateau max_md:text-SmallSize;
  }
}
.inputField {
  width: 100%;
  font-size: 18px;
  /* font-family: "Poppins-Regular"; */
  color: #303030;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  /* box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.10); */

}
.bgNavbar{
  background-image: url("./assets//images/Navbar/Subtract.png");
  background-repeat: no-repeat;
}

.abc {
  position: relative;
}
.abc:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #EF7F1A;
  height: 1.5px;
}
.abc:hover:after {
  width: 100%;
}
.abc,
.abc:after,
.abc:before {
  transition: all .5s;
}

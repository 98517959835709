.sample-slider {
  --add-bottom: 50px !important;
  padding-bottom: var(--add-bottom) !important;
}
.sample-slider [class^="swiper-button-"] {
  top: calc(50% - var(--add-bottom) / 2) !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -24px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}
/* .swiper-slide-gap {
  margin-right: 16px; /* Adjust the value to set the desired gap */
/* }  */
.swiper-wrapper{
    margin-right: 20px;
}
.swiper-wrapper .swiper-slide {
    width: 340px;
}
.swiper-slide .swiper-slide-active {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.swiper-pagination-bullet-active {
  background-color: #FFBA08;
}
.swiper-pagination-bullet{
  background-color: #FFCD9F !important;
}



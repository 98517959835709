.navbarMain{
    background: radial-gradient(169.40% 169.40% at 50.00% 49.99%, #FFF9C7 0%, #FECE49 100%);
}
.waveBg{
    background-image: url("../assets//images/Home/Wave_Bg.png");
    background-repeat: round;
    background-position: inherit;
    background-size: contain;
}
.footerBg{
    background-image: url("../assets/images/Footer/FooterBack.svg");
    background-position-x:center;
}
@media(max-width:1300px){
    .waveBg{
        background-image: url("../assets//images/Home/Products\ BG.png");
        object-fit: cover;
    }
    .footerBg{
        background-image: url("../assets/images/Footer/1300_\ Footer.png");
        background-repeat: no-repeat;
    }
} 
@media(max-width:980px){
    .waveBg{
        background-image: url("../assets//images/Home/Products\ BG980C.png");
    }
    .footerBg{
        background-image: url("../assets/images/Footer/980_\ Footer.png");
        background-repeat: no-repeat;
    }
} 
@media(max-width:768px){
    .waveBg{
        background-image: url("../assets//images/Home/Products\ BG\ 768.png");
    }
    .footerBg{
        background-image: url("../assets/images/Footer/768_Footer.png");
        background-repeat: no-repeat;
    }
} 
@media(max-width:425px){
    .waveBg{
        background-image: none;
        background: #FCE5D1;
    }
    .footerBg{
        background-image: none;
        background: #FFF1B2;
        background-repeat: no-repeat;
    }
} 